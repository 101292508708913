import "leaflet/dist/leaflet.css";
import L from "leaflet";
// place countries location on header
// make ajax call to get the json data from all locations
$.get("/d_c", (addresses, status) => {
  /*
    function showLocation() {
      var data = JSON.parse(localStorage.getItem("location"));
      if (data.selected) {
        $("#location").html(data.selected.at + "<br>" + data.selected.zip);
        $("#phone").html(data.selected.phone[0]);
        $("#phone").attr("href", data.selected.phone[0].replace(/ /g, ""));
      }
    }
  // check if the user has geolocation
  // then get the clients data and check if companies headquarters are in that country
  if (navigator.geolocation && !localStorage.getItem("location")) {
    navigator.geolocation.getCurrentPosition(function(nav) {
      $.get("https://nominatim.openstreetmap.org/?format=json&addressdetails=1&q=" + nav.coords.latitude + "," + nav.coords.longitude, function(data, status) {
        if (status === "success") {
          var selected = addresses.find(o => o.country.toLowerCase() === data[0].address.country.toLowerCase());
          localStorage.setItem("location", JSON.stringify({
            status: "success",
            selected: selected
          }));
          showLocation();
        } else {
          localStorage.setItem("location", JSON.stringify({
            status: "error",
            address: data[0] // show default location
          }));
        }
      });
    });
  }
  showLocation();
  */
  if (status === "success") {
    if (document.getElementById("map")) {
      var map = L.map("map", {
        center: [43.5290705, 17.2881271],
        zoom: (window.innerWidth < 996) ? 5 : 6,
        scrollWheelZoom: false
      });
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors",
      }).addTo(map);
      var micon = L.icon({
        iconUrl: "/js/assets/2b3e1faf89f94a483539.png",
        iconSize: [25, 41],
      });
      for (var i = 0; i < addresses.length; i++) {
        var target = addresses[i];
        L.marker(target["geo"], {
          icon: micon,
        }).addTo(map).bindPopup("<h6>" + target["name"] + "</h6>" + target["at"] + "<br>" + target["zip"] + "<br><br> <a href=\"tel:" + target["phone"][0].replace(/ /g, "") + "\">" + target["phone"][0] + "</a><br><br> <a href=\"mailto:" + target["email"] + "\">" + target["email"] + "</a>");
      }
    }
  }
});
